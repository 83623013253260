<template>
  <div class="">
    <ControlNavbar/>
    <h1 class="header">{{ qr.label }}</h1>

    <div class="container-fluid">
      <div class="banner" v-if="qr.banner">
        <Banner :banner="qr.banner"/>
      </div>
      <button v-if="!camOn" @click="camOn = true">{{ locale.cam_on }}</button>
      <qrcode-stream v-if="qr && camOn" @decode="onDecode"></qrcode-stream>
    </div>
    <a-modal
        v-model="visible"
        v-if="qrRes"
        title=""
        @ok="toggleModal"
        :footer="null"
    >
      <div v-if="qrRes.data.type == 'pay'" class="container">
        <h6>{{ qrRes.data.header }}</h6>
        <p>{{ qrRes.data.label }}</p>
        <hr/>
        <div
            v-for="(item, index) in qrRes.data.items"
            :key="index"
            class="row d-flex justify-content-between margin-lr"
        >
          <p>{{ item.label }}</p>
          <p>{{ item.text }}</p>
        </div>
        <hr/>
        <div class="row d-flex justify-content-between margin-lr">
          <p>{{ qrRes.data.total.label }}</p>
          <p>{{ qrRes.data.total.text }}</p>
        </div>
        <div
            class="row d-flex justify-content-center"
            v-for="(button, index) in qrRes.data.buttons"
            :key="`${index}_`"
        >
          <!-- <p class="danger">dont forget to handle credit card</p> -->
          <button class="whish-button margin-5" @click="payBy(button.text)">
            {{ button.label }}
          </button>
        </div>
      </div>
    </a-modal>
    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
    >
      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldQR="handleOldQR"
      />
    </a-modal>
    <loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
// import countriesCode from "../scripts/countries";
import axios from "axios";
// import Slick from "vue-slick";

// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "SpecificBill",
  components: {ControlNavbar, Banner, PaymentCC, Loading},
  data() {
    return {
      locale: null,
      loader: false,
      visible: false,
      qr: null,
      camOn: true,
      qrRes: null,

      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },
    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
      });
    },
    payBy(paymentType) {
      console.log(paymentType);
      if (paymentType == "creditcard") {
        this.apiBody = {};
        this.paymentOption = paymentType;
        let id = this.qrRes.data.id;
        this.paymentOptionsVisible = true;
        this.apiBody.id = id;
        this.apiBody.type = this.qr.type;
        this.apiBody.fetchPrice = true;
        this.apiBody.denominationId = this.qr.id;
      } else if (paymentType == "whish") {
        this.setLoader(true);
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/pay`, {
              id: this.qrRes.data.id,
            })
            .then((result) => {
              this.setLoader(false);
              if (this.checkApiRes(result, {id: this.qrRes.data.id,}, "sale/pay", "post")) {
                // ok
                console.log("qr res payment: ", result);
                this.success(
                    result.data.dialog.title,
                    result.data.dialog.message
                );
                this.toggleModal();
                this.camOn = true;
              }
            });
      } else {
        alert("error: unknown payment type");
      }
    },
    toggleModal() {
      this.visible = !this.visible;
    },
    switchQrCam() {
      this.camOn = !this.camOn;
    },
    onDecode(decodedString) {
      console.log(decodedString);
      let payload = {
        id: this.qr.data.id,
        qr: decodedString,
      };
      this.switchQrCam();
      this.setLoader(true);
      this.getAxios().post(`${this.$session.get("baseURI")}data/link`, payload).then((result) => {
        this.setLoader(false);
        if (this.checkApiRes(result, payload, "data/link", "post")) {
          // ok
          console.log("qr sent", result);
          this.qrRes = result.data;
          this.toggleModal();
        }
      });
    },
    handleOldQR(values) {
      this.paymentOptionsVisible = false;
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/pay`, values)
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, values, "sale/pay", "post")) {
              // ok
              console.log("qr res payment: ", result);
              this.success(result.data.dialog.title, result.data.dialog.message);
              this.toggleModal();
              this.camOn = true;
            }
          });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
  },
  created() {
    this.checkLocal();
    console.log(
        "QR: ",
        this.$route.params.object || this.$session.get("backup")
    );

    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldQR(values);
    }

    if (!this.$route.params.object) {
      // undefined
      this.qr = this.$session.get("backup");
    } else {
      this.qr = this.$route.params.object;
      this.$session.set("backup", this.$route.params.object);
    }
  },
};
</script>
<style>
/* .qrcode-stream-overlay {
  width: 50% !important;
  height: 50% !important;
  position: absolute;
  top: 25% !important;
  left: 25% !important;
  border: 2px dashed red !important;
} */
</style>
<style lang="scss" scoped>
.center {
  text-align: center;
}

.margin-5 {
  margin: 5%;
}

.margin-lr {
  margin-right: 5%;
  margin-left: 5%;
}

.billPairs {
  background-color: grey;
  border-radius: 45px;
  color: white;
  padding: 2px;
}

.whish-button {
  background-color: #e62946;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: initial;
  font-size: 16px;
  width: -webkit-fill-available;
}

.header {
  padding: 20px;
  background: #e6e6e6;
  box-shadow: 7px 6px 5px grey;
}
</style>
